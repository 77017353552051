import { VIcon } from 'vuetify/lib'

// import '../../../../src/assets/stylus/jcon/style.styl'
// import '../../../../src/assets/stylus/ui/_dataGrid.styl'
// import '../../../../src/assets/stylus/ui/component/_jDatatableGroupedColumn.styl'
import '../../../../src/assets/stylus/ui/component/_kim_p6viewer.styl'

import __C from '../../../../src/primitives/_constant_'
import DatatableExcelMixin from '../../mixins/datatable.excel.mixin'

import JSvgBarProgress from '../JSvgBarProgress'
import GanttTimeline from '../../svgchartlib/01_Charts/99_Common/Timelines/MilestoneMain'
import GanttSchedule from '../../svgchartlib/01_Charts/99_Common/Timelines/SingleItemSchedule'
import KimP6Activities from '../../../kim/KimP6Activities'
import GroupCriticalSummary from '../../../../src/components/china1/GroupCriticalSummary'

export default {
  name: 'j-data-grid-grouped-column',
  mixins: [
    DatatableExcelMixin,
  ],
  components: {
    VIcon,
    KimP6Activities
  },
  props: {
    freezeColumn: Number | String,
    headers: { type: Array, default: () => ([]) },
    items: { type: Array, default: () => ([]) },
    presetSource: { type: Array, default: () => ([]) },
    summaries: { type: Array, default: () => ([]) },
    tableAttrs: { type: Object, default: () => ({ style: {}, svg: {} }) },
    tableType: String,
    titleAttrs: { type: Object, default: () => ({}) },

    // p6 props ---------
    idx: Number,
    inputFilter: String,
    filteredValues: { type: Object, default: () => ({}) },
    // ------------------

    filterString: String,
  },
  data: () => ({
    textAlignToFlex: {
      left: 'flex-start',
      center: 'center',
      right: 'flex-end'
    },
    basePropNames: ['width', 'tSql', 'cScript'],
    userConfigPropNames: [
      'type'      , 'text'      , 'value' , 'visible' , 'sortable'        , 
      'sortOption', 'sortOrder' , 'depth' , 'siblings', 'siblingPosition' , 
    ],
    generalPropNames: [
      'type'          , 'text'          , 'value'   , 'putValue'    , 'width'     , 
      'alignRowValue' , 'visible'       , 'sortable', 'sortOption'  , 'sortOrder' , 
      'styleAttr'     , 'styleAttrData' , 'svgAttr' , 'svgAttrData' , 'link'      , 
      'numFormat'     , 'maxLength'     , 'nowrap'  , 'norepeat'    , 'linebreak' ,
      'indented'      , 'levelColName'  , 'size'    , 'keyColName'
    ],
    injectedPropNames: ['trindex', 'colspan', 'rowspan'],
    propsByNames: [
      'depth'         , 'siblings'    , 'siblingPosition' , 'parentPosition', 'rowspan'   ,
      'alignRowValue' , 'putValue'    , 'styleAttr'       , 'styleAttrData' , 'svgAttr'   , 
      'svgAttrData'   , 'width'       , 'link'            , 'numFormat'     , 'maxLength' ,
      'filterString'  , 'nowrap'      , 'norepeat'        , 'type'          , 'linebreak' ,
      'indented'      , 'levelColName', 'size'            , 'keyColName'
    ],

    flattened: [],
    easyFormedHeaders: {},
    previousValues: {},
    siftedHeaders: [],
    stickyColumns: {},
    tdContentGap: 1,
    maxDepth: null,   // only for the visible headers, not for the whole header
    ganttInfo: {},
    ganttExported: {},

    // p6 data ----------------
    collapsed: {},
    collstorageName: 'P6_COLLAPSED_STATUS',
    p4act: {}, // for the activity component props
    p4sector: {}, // for the sector group data
    // ------------------------
  }),
  computed: {
    requestPreset() {
      if (!this.presetSource || this.presetSource.length === 0) return []
      return [...this.presetSource]
    },
    styleAttrAvailable() { return this.tableAttrs ? (Object.keys(this.tableAttrs.style).length > 0 ? true : false) : false },
    svgAttrAvailable() { return this.tableAttrs ? (Object.keys(this.tableAttrs.svg).length > 0 ? true : false) : false },

    // p6 values --------------
    p6props() {
      /**
       * p6 property definition in the "info" propperty of the jsonProps (headers)
       * 
       * {
       *   "type": "info",
       *   "visible": false,
       *   "p6props": {
       *       "levels": {
       *           "LV1": {
       *               "style": {
       *                   "background-color": "#45c5e5",
       *                   "border": "1px solid #268fa9"
       *               }
       *           },
       *           "LV2": {
       *               "style": {
       *                   "background-color": "#3cd995",
       *                   "border": "1px solid #23a069"
       *               }
       *           },
       *           "LV3": {
       *               "style": {
       *                   "background-color": "#bddc41",
       *                   "border": "1px solid #8ca528"
       *               }
       *           },
       *           "LV4": {
       *               "style": {
       *                   "background-color": "#e8b254",
       *                   "border": "1px solid #a3782c"
       *               }
       *           },
       *           "LV5": {
       *               "style": {
       *                   "background-color": "#ed8a80",
       *                   "border": "1px solid #a8473d"
       *               }
       *           }
       *       },
       *       "levelNoColName": "LV_NO",
       *       "groupDisplayColNames": [
       *         "ACTIVITY_DESCR",
       *         "BL_SD",
       *         "BL_FD",
       *         "PLAN_SD",
       *         "PLAN_FD",
       *         "ACTUAL_SD",
       *         "ACTUAL_FD",
       *         "ORIGINAL_DU",
       *         "REMAIN_DU",
       *         "ACTUAL_PROG",
       *         "VAR_BL_SD",
       *         "VAR_BL_FD",
       *         "TOTAL_FLOAT"
       *       ],
       *       "groupLabelColName": "ACTIVITY_DESCR",
       *       "activityQeuryName": "P6 Index Activities",
       *   },
       *   ...
       * }
       */

      let info_ = this.headers.find(prop => prop.type == 'info')
      if(!info_ || !info_.p6props) return null
      return info_.p6props
    }
    // ------------------------
  },
  watch: {
    headers: {
      handler(val) {
        if(!val || val.length === 0) return

        this.init(val)
        this.init4act(val)
        this.init4sector(val)
        this.setStickyValues(val)
      },
      deep: true
    },
    requestPreset: {
      handler(newFlattened) {
        if (!newFlattened || newFlattened.length === 0) return []
        this.$emit('update-preset', this.updatePreset(newFlattened))
      },
      deep: true
    },
    
    // p6 watchers ### -------------------------------------------------
    items: {
      handler() { this.setCollapse() },
      deep: true
    }
    // -----------------------------------------------------------------
  },
  created() {
    this.flattened = []
    this.easyFormedHeaders = {}
  },
  methods: {
    init(headers) {
      this.siftedHeaders = this.siftInvisible(JSON.parse(JSON.stringify(headers)))
      this.flattened = this.flatten([...headers], null, this.getHeaderDepth(headers))

      // ### maxDepth, use of common reference for the subsequent process -----
      this.maxDepth = this.getHeaderDepth(this.siftedHeaders)
      // ### ------------------------------------------------------------------

      let flattenedVisible_ = this.flatten (
        this.setHeaderProperites(JSON.parse(JSON.stringify(this.siftedHeaders))), 
        null, 
        this.maxDepth
      )

      this.easyFormedHeaders = {}

      this.flattened.forEach(prop => {
        this.easyFormedHeaders[prop.value] = {}

        this.propsByNames.forEach(k => {
          let propVisible_ = flattenedVisible_.find(prop_ => prop_.value == prop.value)
          this.easyFormedHeaders[prop.value][k] = propVisible_ ? propVisible_[k] : prop[k]
        })
      })
    },
    init4act(headers) {
      let h__ = headers.filter(h => h.type != 'starter')
      let p__ = {}

      p__.siftedHeaders = this.siftInvisible(JSON.parse(JSON.stringify(h__))),
      p__.flattened = this.flatten([ ...h__ ], null, this.getHeaderDepth(h__))
      p__.maxDepth = this.getHeaderDepth(p__.siftedHeaders)
      p__.easyFormedHeaders = {}
      p__.stickyColumns = {}

      let flattenedVisible_ = this.flatten(this.setHeaderProperites(JSON.parse(JSON.stringify(p__.siftedHeaders))), null, p__.maxDepth)

      p__.flattened.forEach(prop => {
        p__.easyFormedHeaders[prop.value] = {}

        this.propsByNames.forEach(k => {
          let propVisible_ = flattenedVisible_.find(prop_ => prop_.value == prop.value)
          p__.easyFormedHeaders[prop.value][k] = propVisible_ ? propVisible_[k] : prop[k]
        })
      })

      let left = 0
      let info = h__.find(h => h.type == 'info')
      let cols = info && info.stickyColNames ? info.stickyColNames.filter(c => c != '__STARTER__') : []

      cols.forEach(c => {
        let colProp = p__.flattened.find(h => h.visible && h.value == c)
        if(!colProp) return

        let index = Object.keys(p__.stickyColumns).length
        p__.stickyColumns[colProp.value] = { index, left }

        left += colProp.width || 50
      })

      this.p4act = p__
    },
    init4sector(header) {
      this.p4sector = {}
    },
    genDatatable() {
      if(
        this.flattened.length === 0 || 
        Object.keys(this.easyFormedHeaders).length === 0 ||
        Object.keys(this.tableAttrs.table).length === 0 ||
        Object.keys(this.tableAttrs.header).length === 0 ||
        Object.keys(this.tableAttrs.body).length === 0
      ) return ''

      this.flattened_ = this.flattened.filter(item => item.type != 'config' && !!item.text)
      this.flattened_ = this.flattened_.map(item => {
        let t_ = {}
        this.userConfigPropNames.forEach(k_ => { t_[k_] = item[k_] })
        return t_
      })
      this.$emit('flattened', this.flattened_)

      if(this.tableAttrs.table.border.applied == 'Y') {
        let border_ = this.tableAttrs.table.border
        var style = {
          // 'border-top': `solid ${border_.top.width}px ${border_.top.color}`,
          'border-right': `solid ${border_.right.width}px ${border_.right.color}`,
          // 'border-bottom': `solid ${border_.bottom.width}px ${border_.bottom.color}`,
          'border-left': `solid ${border_.left.width}px ${border_.left.color}`,
        }
      }
      
      let data = {
        class: {
          j_intended: true,
          j_datatable_grouped_column: true,
          sticky_header: true,
          multi: this.getHeaderDepth(this.siftedHeaders) > 1 ? true : false
        },
        style
      }

      // if(this.freezeColumn) {
      //   for(let i = 1; i <= this.freezeColumn; i++) data.class[`sticky_col${i}`] = true
      // }
  
      // setTimeout(() => {
      //   this.$emit('resize', {
      //     width: d3.select('.j_intended').node().getBoundingClientRect().width,
      //     height: d3.select('.j_intended').node().getBoundingClientRect().height,
      //   })
      // }, 350)

      let el = this.$createElement('table', data, [this.genHeader(), this.genBody()])
      this.$emit('complete')
      
      return el
    },
    genHeader() {
      // get visible header data only
      let headers_ = JSON.parse(JSON.stringify(this.siftedHeaders))
      if(headers_.length === 0) return

      // ### Set Style -------------------------------------------------
      let style_ = { 
        'font-family': this.tableAttrs.header.font,
        // 'border-bottom': `solid ${this.tableAttrs.header.border.width}px ${this.tableAttrs.header.border.color}`,
      }
      let class_ = {}

      if(!this.tableAttrs.header.background.gradient) style_['background-color'] = this.tableAttrs.header.background.color
      else class_[`${this.tableAttrs.header.background.gradient}_${this.tableAttrs.header.background.color}_v`] = true

      let data = {
        style: style_,
        class: class_
      }
      // ### -----------------------------------------------------------

      let headerProperties = this.setHeaderProperites(headers_)
      let trs = this.genHeaderTRs(headerProperties)

      this.$emit('header-props-assigned', headerProperties)
      this.headerProperties = headerProperties

      // summary data at the top of the table body.
      let trsSummary = []
      if(this.summaries.length > 0) trsSummary = this.genTRsSummary()

      return this.$createElement('thead', data, [ ...trs, ...trsSummary ])
    },
    genHeaderTRs(props) {
      let stickyPos = []
      let lastPos = this.tableAttrs.header.styles.map(_ => parseFloat(_.height)).reduce((acc, current)=>{
        stickyPos.push(acc);
        return acc + current
      }, 0)

      stickyPos.push(lastPos)
      return [...Array(this.getHeaderDepth(props)).keys()].map(i => {
        // Set Style -----------------------
        if(this.tableAttrs.header.styles[i]) {
          let reqStyles_ = this.tableAttrs.header.styles[i]
          let class_ = reqStyles_.fontStyle == 'regular' ? '' : reqStyles_.fontStyle
          let style_ = {}

          style_['height'] = `${reqStyles_.height}px`
          style_[`--sticky--top`] = `${stickyPos[i]}px`
          // style_['color'] = `${reqStyles_.tColor}px`
          // style_['font-size'] = `${reqStyles_.fontSize}px`

          // if(!reqStyles_.bColorGradient) style_['background-color'] = reqStyles_.bColor
          // else class_ = `${class_ ? class_ + ' ' : ''}${reqStyles_.bColorGradient}_${reqStyles_.bColor}_v`
    
          var data = {
            // attrs: { stikyH: reqStyles_.height },
            class: class_,
            style: style_
          }
        }
        // ---------------------------------

        return this.$createElement('tr', data, this.genTHs(props, i))
      })
    },
    genTHs(props, i, ths=[], width=0) {
      props.forEach(h => {
        if(h.trindex === i) ths.push(this.genTH(h))
        if(h.type == 'group') this.genTHs(h.children, i, ths)
      })
      return ths
    },
    genTH(h) {
      let classTH_ = ''
      let classBorder_ = '__border___'
      let classBG_ = ''
      let classText_ = '__text___'
      let styleTH_ = {}
      let styleBorder_ = {}
      let styleBG_ = {}
      
      // Set style for the theader-top-border
      if(this.tableAttrs.table.border.applied == 'Y' && h.trindex === 0)
        styleBorder_['border-top'] = `solid ${this.tableAttrs.table.border.top.width}px ${this.tableAttrs.table.border.top.color}`

      // Set Style -----------------------
      if(this.tableAttrs.header.styles[h.trindex]) {
        let reqStyles_ = this.tableAttrs.header.styles[h.trindex]

        classTH_ = reqStyles_.fontStyle == 'regular' ? '' : reqStyles_.fontStyle
        
        styleTH_['font-size'] = `${reqStyles_.fontSize}px`
        styleTH_['color'] = reqStyles_.tColor
        
        // if(h.trindex !== 0 && h.siblingPosition == h.siblings) {
        if(h.trindex !== 0 && h.siblingPosition === 1) {
          let index_ = this.getLineBlockIndex(h.parentPosition)
          let parStyles_ = index_ < 0 ? reqStyles_ : this.tableAttrs.header.styles[index_]
          styleBorder_['border-left'] = `solid ${parStyles_.border.width}px ${parStyles_.border.color}`

        } else if(h.siblingPosition !== 1) 
          styleBorder_['border-left'] = `solid ${reqStyles_.border.width}px ${reqStyles_.border.color}`

        // if(Number(h.trindex) + Number(h.rowspan) < this.maxDepth) styleBorder_['border-bottom'] = `solid ${reqStyles_.border.width}px ${reqStyles_.border.color}`

        if(!reqStyles_.background.gradient) styleBG_['background-color'] = reqStyles_.background.color
        else classBG_ = `${reqStyles_.background.gradient}_${reqStyles_.background.color}_v`
        if(reqStyles_.background.opacity && reqStyles_.background.opacity < 1) styleBG_['opacity'] = reqStyles_.background.opacity
      }

      // Set style for the theader-bottom-border
      if(Number(h.trindex) + Number(h.rowspan) == this.maxDepth)
        styleBorder_['border-bottom'] = `solid ${this.tableAttrs.header.border.width}px ${this.tableAttrs.header.border.color}`

      if(h.children && h.children.length > 0) {
        let width = this.getHeaderWidth(h.children)
        h.width = width.reduce((a, b) => a + b, 0)
      }
    
      if (h.type == 'spacer') {
        styleTH_['width'] = h.width ? `${h.width}px` : '100%' 
      } else if(h.type == 'gantt') {
        classTH_ = classTH_ ? `${classTH_} gantt_timeline` : 'gantt_timeline'
      } else {
        styleTH_['width'] = `${h.width}px`
        styleTH_['max-width'] = `${h.width}px`
        styleTH_['min-width'] = `${h.width}px`
      }
      // ---------------------------------

      // set sticky-column props ---------
      if(this.stickyColumns[h.value]) {
        let stickyClassName = `sticky_col${this.stickyColumns[h.value].index}`
        classTH_ = classTH_ ? `${classTH_} ${stickyClassName}` : stickyClassName
        styleTH_['--sticky--left'] = `${this.stickyColumns[h.value].left}px`

      } else if(h.type == 'group') {
        // get very first element of the group then check if sticky element
        let sticky__ = this.stickyColumns[this.getHeaderNames(h.children)[0]]

        if(sticky__) {
          let stickyClassName = `sticky_col${sticky__.index}`
          classTH_ = classTH_ ? `${classTH_} ${stickyClassName}` : stickyClassName
          styleTH_['--sticky--left'] = `${sticky__.left}px`
        }
      }
      // ---------------------------------

      let dataTH = { 
        attrs: { colspan: h.colspan, rowspan: h.rowspan },
        class: classTH_,
        style: styleTH_
      }
      let dataBorder = {
        class: classBorder_,
        style: styleBorder_
      }
      let dataBG = { 
        class: classBG_,
        style: styleBG_
      }

      if (h.type == 'starter') {
        var elText_ = this.genCollapseAllAction()

      } else if (h.type == 'config') {
        let data_ = {
          class: 'jcon_config',
          on: { click: e => { this.$emit('click-config', e) }}
        }
        elText_ = this.$createElement('div', { class: classText_ }, [this.$createElement('button', data_, [])])

      } else if (h.type == 'gantt') {
        let data_ = {
          props: {
            items: this.items,
            target: h.target,
            milestone: h.timeline || null,
            cutoffFilter: h.cutoffFilter
          },
          style: {
            position: 'absolute',
            bottom: '-1px'
          },
          on: { 
            complete: this.onGanttComplete,
            'to-xml-string': this.onGanttXmlExported
          }
        }        
        elText_ = this.$createElement(GanttTimeline, data_)

      } else {
        if(h.inputFilter) {
          var elIF__ = this.$createElement('i', { 
            class: 'mdi mdi-filter',
            style: {
              'font-size': '10px',
              'color' : '#898989',
              'margin': '2px'
            }
          })
        }
        if(h.html) {
          let elHtml_ = this.$createElement('div', { style: {'height': 'fit-content' ,'font-size' :'10px'}, domProps: { innerHTML: h.html }})
          elText_ = this.$createElement('div', { class: classText_ },  [elIF__, elHtml_])
        } 
        else elText_ = this.$createElement('div', { class: classText_ }, [elIF__, h.text])
      }

      let elBG_ = this.$createElement('div', dataBG)
      let elBorder_ = this.$createElement('div', dataBorder, [elBG_, elText_])
      // Background for the 'sticky' header --------------------------
      let backgroundData = {
        class: '__sticky_header_background__'
      }
      if(!this.tableAttrs.header.background.gradient) backgroundData.style = { 'background-color': this.tableAttrs.header.background.color }
      else backgroundData.class = `${backgroundData.class} ${this.tableAttrs.header.background.gradient}_${this.tableAttrs.header.background.color}_v`
      // -------------------------------------------------------------
      return this.$createElement('th', dataTH, [this.$createElement('div', backgroundData, [elBorder_])])
    },
    genCollapseAllAction() {
      let styleIcon = {
        margin: '0',
        fontSize: '1.2rem',
        color: '#000'
      }
      let styleButton = {
        width: '1.4rem',
        height: '1.4rem',
        margin: '1px',
        backgroundColor: '#fff',
        border: '1px solid rgba(0, 0, 0, .75)'
      }

      let btnPlus = this.$createElement('button', {
        style: styleButton,
        class: {
          btn_collapse_open: true
        },
        on: { 
          click: () => { this.collapseAll(true) }
        }
      }, [this.$createElement(VIcon, { style: styleIcon }, ['mdi-plus'])])

      let btnMinus = this.$createElement('button', {
        style: styleButton,
        class: {
          btn_collapse_close: true
        },
        on: { 
          click: () => { this.collapseAll(false) }
        }
      }, [this.$createElement(VIcon, { style: styleIcon }, ['mdi-minus'])])

      return this.$createElement('div', { 
        class: { __text___: true },
        style: { flexDirection: 'column' }
      }, [btnPlus, btnMinus])
    },
    genBody() {
      let headerNames = this.getHeaderNames(this.siftedHeaders)
      let lenHeader = headerNames.length
      let lenSticky = Object.keys(this.stickyColumns).length

      if(lenSticky > 0) {
        var style = {}
        this.getStyleBorder(style, headerNames[0], 'cell')
        
        var tds = [
          this.$createElement('td', { 
            attrs: { colspan: lenSticky },
            style: { ...style, position: 'sticky', left: '1px', zIndex: 1 }
          }, [this.genP6Viewer()]),

          this.$createElement('td', { 
            attrs: { colspan: lenHeader-lenSticky }
          }, [this.genP6Viewer(true)])
        ]

      } else {
        // 
      }
      // let td = this.$createElement('td', {
      //   attrs: { colspan: this.getHeaderNames(this.siftedHeaders).length }
      // }, [this.genP6Viewer()])

      return this.$createElement('tbody', [this.$createElement('tr', tds)])
    },
    genTRsSummary() {
      let headerNames = this.getHeaderNames(this.siftedHeaders)

      let colNames = ['__GANTT__'] // for the gantt column in default, it could (not) be exist
      this.summaries.forEach(item => { colNames = Array.from(new Set([ ...colNames, ...Object.keys(item)])) })

      let indecies = colNames.map(k => headerNames.findIndex(h => h == k)).filter(index_ => index_ >= 0)
      // Find the first met column index which can be the length of the
      // label's space (colspan).
      let labelColspan = Math.min(...indecies)

      // Find the last met column index which can be the length of the
      // rest of the empty space (colspan).
      let tailColspan = headerNames.length - 1 - Math.max(...indecies)
      // get only the available column ranges for summary result
      let targetHeaderEls = headerNames.slice(Math.min(...indecies), Math.max(...indecies) + 1)

      let stickyPos = this.tableAttrs.header.styles.map(_ => parseFloat(_.height)).reduce((acc, current)=>{
        return acc + current
      }, 0)

      let data = {}
      if(this.tableAttrs.body && this.tableAttrs.body.summary && Object.keys(this.tableAttrs.body.summary).length > 0) {
        data.class = {
          __datatable___summary_row_: true
        }
        data.style = {
          '--sticky--top': `${stickyPos}px`,
          'height': `${this.tableAttrs.body.summary.height}px`,
          // 'background-color': this.tableAttrs.body.summary.bColor,
          'font-family': this.tableAttrs.body.summary.font,
          // 'border-bottom': `solid ${this.tableAttrs.body.summary.border.width}px ${this.tableAttrs.body.summary.border.color}`
        }
      }

      return this.summaries.map(summary => this.$createElement('tr', data, this.genTHsSummary({
        headerNames: targetHeaderEls,
        labelColspan,
        tailColspan,
        summary
      })))
    },
    genTHsSummary(info) {
      let data = {}
      if(this.tableAttrs.body && this.tableAttrs.body.summary && Object.keys(this.tableAttrs.body.summary).length > 0) {
        data = {
          attrs: { colspan: info.labelColspan },
          class: `summary ${this.tableAttrs.body.summary.fontStyle}`,
          style: { 
            'text-align': 'center' ,
            'font-size': `${this.tableAttrs.body.summary.fontSize}px`,
            'color': this.tableAttrs.body.summary.color
          }
        }
      }

      // set sticky-column props ---------
      if(Object.keys(this.stickyColumns).length > 0) {
        data['class'] = data['class'] ? `${data['class']} sticky_col0` : 'sticky_col0'
        data['style']['left'] = '1px'
        data['style']['z-index'] = 3
      }
      // ---------------------------------
      
      // Label TD at the start of the columns
      let targetNames_ = this.getHeaderNames(this.siftedHeaders)
      let colIndex_ = targetNames_.findIndex(colName => colName == info.headerNames[0])
      if(colIndex_ > 0) this.getStyleBorder(data.style, targetNames_[colIndex_-1], 'summary')
      let ths_ = [this.$createElement('th', data, this.setStickyBackground([info.summary.text || '']))]
      // Data TDs
      ths_ = [ ...ths_, ...info.headerNames.map(colName => this.genTD(info.summary, colName, true))]
      // Empty TD at the end of the columns
      if(info.tailColspan > 0) {
        if(info.tailColspan > 1) var data_ = { attrs: { colspan: info.tailColspan }}
        ths_.push(this.$createElement('th', data_, this.setStickyBackground([''])))
      }

      return ths_
    },
    genTDs(item) {
      let names = this.getHeaderNames(this.siftedHeaders)
      return names.map((colName, i) => this.genTD(item, colName, false, i))
    },
    genTD(item, colName, summary=false) {
      item.dataType = summary ? 'summary' : 'general'

      let tag_ = summary ? 'th' : 'td'
      let columnInfo_ = this.easyFormedHeaders[colName]
      if(!columnInfo_) {
        console.error(`[USER: undefined] Invalid column name, ${colName}`)
        return this.$createElement(tag_, [''])
      }

      let bodyProps_ = this.tableAttrs.body
      let cellProps_ = this.tableAttrs.body.cell
      let class_ = ''
      let style_ = {}

      // Set Style -----------------------
      this.getStyleBorder(style_, colName, summary ? 'summary' : 'cell')

      if(!summary) cellProps_.fontStyle

      style_['font-family'] = cellProps_.font
      style_['font-size'] = `${cellProps_.fontSize}px`
      style_['color'] = cellProps_.color

      let summaryData = {}
      if(summary && bodyProps_ && bodyProps_.summary && Object.keys(bodyProps_.summary).length > 0) {
        summaryData = {
          class: `summary ${bodyProps_.summary.fontStyle}`,
          style: {
            'font-family': bodyProps_.summary.font,
            'font-size': `${bodyProps_.summary.fontSize}px`,
            'color': bodyProps_.summary.color,
            'background-color': bodyProps_.summary.bColor,
          }
        }
      }

      let dataTD = {
        class: class_ ? class_ + (summaryData.class ? ' ' + summaryData.class : '') : summaryData.class,
        style: {
          ...style_,
          ...summaryData.style
        }
      }

      // set tooltip
      if(columnInfo_.nowrap) dataTD.attrs = { title: item[colName] }
      
      // set sticky-column props ---------
      if(this.stickyColumns[colName]) {
        let stickyClassName = `sticky_col${this.stickyColumns[colName].index}`

        dataTD.class = dataTD.class ? `${dataTD.class} ${stickyClassName}` : stickyClassName
        dataTD.style['position'] = 'sticky'
        dataTD.style['left'] = `${this.stickyColumns[colName].left}px`
        dataTD.style['z-index'] = summary ? 3 : 1
        // dataTD.style['--sticky--left'] = `${this.stickyColumns[colName].left}px`
      }
      // ---------------------------------

      if(columnInfo_.indented) dataTD.style['padding-left'] = `${item[columnInfo_.levelColName]*15}px`
  
      let default_ = (gantt=false) => {
        let ganttData = {
          props: {
            item      : item,
            size      : columnInfo_.size,
            keyName   : columnInfo_.keyColName,
            syncparent: this.ganttInfo
          },
          // on: {
          //   'to-xml-string': this.onGanttXmlExported
          // }
        }

        if(summary) {
          ganttData.props.chart = { x: 1, y: 0 }
          ganttData.props.cutoffline = { y1: -2, y2: 3 }
        }

        let elTexts_ = gantt ? [this.$createElement(GanttSchedule, ganttData)] : this.setText(item, colName)
        return this.$createElement(tag_, dataTD, summary ? this.setStickyBackground(elTexts_) : elTexts_)
      }

      let td___ = null
      if(this.styleAttrAvailable && columnInfo_.styleAttr) {
        let styleAttr = columnInfo_.styleAttr
        let colNameCode = (
          !columnInfo_.styleAttrData.code ||
          columnInfo_.styleAttrData.code == 'self' ?
          colName :
          columnInfo_.styleAttrData.code
        )

        if(!item[colNameCode]) {
          console.log(`[USER: column code '${colNameCode}' undefined] Cannot find a column code to be matched with style attribute.`)
          td___ = default_()

        } else if(!this.codeAttrAvailable('style', columnInfo_.styleAttr, item[colNameCode])) {
          console.log(`[USER: Not found] Cannot find style attribute for CODE-VALUE '${item[colNameCode]}'.`)
          td___ = default_()

        } else {
          let codeAttr_ = this.tableAttrs.style[styleAttr].find(style => style.code == item[colNameCode])
          let styledContent_ = this.genStyleAttrContent(item, colName, styleAttr, codeAttr_)
          td___ = this.$createElement(tag_, dataTD, summary ? this.setStickyBackground(styledContent_) : styledContent_)
        }

      } else if(this.svgAttrAvailable && columnInfo_.svgAttr) {
        let svgAttr = columnInfo_.svgAttr
        let colNameCode = (
          !columnInfo_.svgAttrData.code ||
          columnInfo_.svgAttrData.code == 'self' ?
          colName :
          columnInfo_.svgAttrData.code
        )

        if(!item[colNameCode]) {
          console.log(`[USER: column code '${colNameCode}' undefined] cannot find a column code to be matched with svg style attribute.`)
          td___ = default_()

        } else if(!this.codeAttrAvailable('svg', columnInfo_.svgAttr, item[colNameCode])) {
          console.log(`[USER: Not found] Cannot find SVG style attribute for CODE-VALUE '${item[colNameCode]}'.`)
          td___ = default_()

        } else {
          let codeAttr_ = this.tableAttrs.svg[svgAttr].attrs.find(svg => svg.code == item[colNameCode])
          let svgContent_ = this.genSvgAttrContent(item, colName, svgAttr, codeAttr_)
          td___ = this.$createElement(tag_, dataTD, summary ? this.setStickyBackground(svgContent_) : svgContent_)
        }
      } else td___ = default_(columnInfo_.type == 'gantt')

      return td___
    },
    genSvgAttrContent(item, colName, svgAttr, attrs) {
      let data = {
        style: {
          'height': `${21 - this.tdContentGap * 2}px`,
        },
      }
      
      if(svgAttr == 'bar') {
        let colNameValue = (
          !this.easyFormedHeaders[colName].svgAttrData.value ||
          this.easyFormedHeaders[colName].svgAttrData.value == 'self' ? 
          colName :
          this.easyFormedHeaders[colName].svgAttrData.value
        )
        data.style['justify-content'] = 'center'
        data.style['padding'] = '0'
        data.props = {
          svgAttrs: {
            width: Number(this.tableAttrs.svg[svgAttr].width),
            thickness: Number(this.tableAttrs.svg[svgAttr].thickness),
            radius: Number(this.tableAttrs.svg[svgAttr].radius),
            background: this.tableAttrs.svg[svgAttr].background,
            textUnit: this.tableAttrs.svg[svgAttr].textUnit,
            textAlign: this.tableAttrs.svg[svgAttr].textAlign,
            bColor: attrs.bColor,
            tColor: attrs.tColor,
            value: item[colNameValue] ? Math.round(item[colNameValue]) : 0,
          }
        }
        var svg = this.$createElement(JSvgBarProgress, data)

      } else {
        // For the font-icon
        // let data = {
        //   class: [`jcon_${attrs.file.name.toLowerCase()}`]
        // }
        // svg = this.$createElement('i', data)

        let width = this.easyFormedHeaders[colName].width ? `${this.easyFormedHeaders[colName].width - this.tdContentGap * 2}px` : ''
        let justifyContents = this.textAlignToFlex[this.easyFormedHeaders[colName].alignRowValue] || 'center'

        data.style['width'] = width
        data.style['justify-content'] = justifyContents

        let imgData = { 
          attrs: { 
            src: attrs.file.filePath,
            width: this.tableAttrs.svg[svgAttr].size,
            height: this.tableAttrs.svg[svgAttr].size,
          }
        }
        
        let textPosition = this.tableAttrs.svg[svgAttr].textPosition
        // let spanMargin = textPosition == 'left' ? { 'margin-right': '2px' } : (textPosition == 'right' ? { 'margin-left': '2px' } : {})
        // let span = text ? [this.$createElement('span', { style: { ...spanMargin }}, [this.setLink(item, colName)])] : []
        let text = [this.$createElement('div', this.setText(item, colName))]
        let icon = [this.$createElement('img', imgData)]
        let contents = textPosition == 'right' ? [...icon, ...text] : [...text, ...icon]

        svg = this.$createElement('div', data, contents)
      }

      return [svg]
    },
    getStyleBorder(style_, colName, type) {
      let columnInfo_ = this.easyFormedHeaders[colName]
      let styles___ = this.tableAttrs.body[type].styles

      if(!styles___[columnInfo_.depth]) return

      let reqStyles_ = styles___[columnInfo_.depth]

      if(columnInfo_.depth !== 0) {
        let rowspanSum_ = this.getRowspanSum(columnInfo_.parentPosition)
        if(columnInfo_.siblingPosition == columnInfo_.siblings) {
          let index_ = this.getLineBlockIndex(columnInfo_.parentPosition, 'right')
          let parStyles_ = index_ < 0 ? reqStyles_ : styles___[index_]
          style_['border-right'] = `solid ${parStyles_.border.width}px ${parStyles_.border.color}`

        } else if(columnInfo_.depth != rowspanSum_) {
          let childStyles_ = !styles___[rowspanSum_] ? reqStyles_ : styles___[rowspanSum_]
          style_['border-right'] = `solid ${childStyles_.border.width}px ${childStyles_.border.color}`

        } else style_['border-right'] = `solid ${reqStyles_.border.width}px ${reqStyles_.border.color}`

      } else style_['border-right'] = `solid ${reqStyles_.border.width}px ${reqStyles_.border.color}`
    },
    genStyleAttrContent(item, colName, styleAttr, attrs) {
      let textAlign = this.easyFormedHeaders[colName].alignRowValue || 'center'
      let colNameValue = (
        !this.easyFormedHeaders[colName].styleAttrData.value ||
        this.easyFormedHeaders[colName].styleAttrData.value == 'self' ? 
        colName :
        this.easyFormedHeaders[colName].styleAttrData.value
      )
      // attrs.tColor: common attribute for all of the style props.
      // It doesn't need to be set from inside of the style detail
      // except the 'a' link.
      let data = { 
        class: '__styler___', 
        style: {}
      }

      if(styleAttr != 'text') {
        let tableStyle_ = this.tableAttrs.style
        
        if(textAlign != 'center' && tableStyle_[`${styleAttr}TextMargin`]) data.style[`padding-${textAlign}`] = tableStyle_[`${styleAttr}TextMargin`] + 'px'
        if(tableStyle_[`${styleAttr}BoxRadius`]) data.style['border-radius'] = tableStyle_[`${styleAttr}BoxRadius`] + 'px'
        if(tableStyle_[`${styleAttr}MarginTB`]) {
          data.style['top'] = tableStyle_[`${styleAttr}MarginTB`] + 'px'
          data.style['bottom'] = tableStyle_[`${styleAttr}MarginTB`] + 'px'
        }
        if(tableStyle_[`${styleAttr}MarginLR`]) {
          data.style['left'] = tableStyle_[`${styleAttr}MarginLR`] + 'px'
          data.style['right'] = tableStyle_[`${styleAttr}MarginLR`] + 'px'
        }
      }

      if(!attrs) return [this.$createElement('div', data, this.setText(item, colName))]

      data.style.color = attrs.tColor
      if(styleAttr == 'cell') {
        let value_ = item[colNameValue] ? Math.round(item[colNameValue]) : 0
        // data.style['background-color'] = attrs.bColor
        // data.style['width'] = `${value_}%`
        data.style['background'] = `linear-gradient(to right, ${attrs.bColor} ${value_}%, transparent ${value_}%)`

      } else if(styleAttr == 'color') {
        data.style['background-color'] = attrs.bColor

      } // else; for just the text style already be set at the parent.

      return [this.$createElement('div', data, this.setText(item, colName, attrs.tColor))]
    },

    // get methods ### -------------------------------------------------
    getHeaderDepth(h) {
      return Math.max(...this.calHeaderDepth(h).dived) + 1
    },
    getHeaderLength(h, extended=[]) {
      h.forEach(h => {
        if (h.type == 'group') this.getHeaderLength(h.children, extended)
        else extended.push(true)
      })
      return extended.length
    },
    getHeaderWidth(h, widthes=[]) {
      h.forEach(h => {
        if (h.type == 'group') this.getHeaderWidth(h.children, widthes)
        else widthes.push(h.width)
      })
      return widthes
    },
    getHeaderNames(h, flattened = []) {
      h.forEach(h => {
        if (h.type == 'group') flattened = [...this.getHeaderNames(h.children, flattened)]
        else flattened.push(h.value)
      })

      return flattened
    },
    getColnamesForAction(colString) {
      let colParts_ = colString.replace(/\s/g, '').split('=')
      if(colParts_.length === 0) return { source: '', target: '' }
      if(colParts_.length === 1) return { source: colParts_[0], target: colParts_[0] }
      return { source: colParts_[1], target: colParts_[0] }
    },
    getLineBlockIndex(blockInfo, borderDirection='left') {
      if(borderDirection == 'left') {
        if(blockInfo.siblingPosition === 1) return this.getLineBlockIndex(blockInfo.parentPosition)
        if(!blockInfo.parentPosition) return blockInfo.depth
        return blockInfo.depth
      } else {
        if(blockInfo.siblings != blockInfo.siblingPosition) return blockInfo.depth
        if(!blockInfo.parentPosition) return -1
        return this.getLineBlockIndex(blockInfo.parentPosition, borderDirection)
      }
    },
    getRowspanSum(blockInfo, rowspanSum=0) {
      if(!blockInfo.parentPosition) return rowspanSum + blockInfo.rowspan
      return this.getRowspanSum(blockInfo.parentPosition, rowspanSum + blockInfo.rowspan)
    },
    // -----------------------------------------------------------------

    // set methods ### -------------------------------------------------
    setStickyValues(header) {
      this.stickyColumns = {}

      let info = header.find(h => h.type == 'info')
      if(!info) return

      let colNames = info.stickyColNames
      if(!colNames) return

      let left = 1
      colNames.forEach(c => {
        let colProp = this.flattened.find(h => h.visible && h.value == c)
        if(!colProp) return

        let index = Object.keys(this.stickyColumns).length
        this.stickyColumns[colProp.value] = {
          index: index,
          left: left
        }

        left += colProp.width || 50
      })
    },
    setStickyBackground(el=[]) {
      // Background for the 'sticky' header --------------------------
      let data = {
        class: '__sticky_header_background__ __datatable___summary_row_'
      }
      if(this.tableAttrs.body && this.tableAttrs.body.summary && Object.keys(this.tableAttrs.body.summary).length > 0) {
        data.style = {
          'background-color': this.tableAttrs.body.summary.bColor,
          'border-bottom': `solid ${this.tableAttrs.body.summary.border.width}px ${this.tableAttrs.body.summary.border.color}`
        }
      }
      // -------------------------------------------------------------
      return [this.$createElement('div', data, el)]
    },
    setText(item, colName, tColor) {
      if(!item[colName] || this.easyFormedHeaders[colName].putValue === false) return ''

      let text = this.easyFormedHeaders[colName].numFormat ? item[colName].toLocaleString() : item[colName]
      if(this.easyFormedHeaders[colName].norepeat) {
        if(!this.previousValues[colName]) this.previousValues[colName] = text
        else {
          if(this.previousValues[colName] == text) text = ''
          else this.previousValues[colName] = text
        }

      } else if(this.easyFormedHeaders[colName].link) text = this.setLink(item, colName, text, tColor)

      let data = { class: '', style: {} }
      let columnInfo_ = this.easyFormedHeaders[colName]
      let bodyProps_ = this.tableAttrs.body
      let textAlign = columnInfo_.alignRowValue || 'center'

      data.class = `__text___${textAlign}`
      if(this.easyFormedHeaders[colName].nowrap) {
        data.class = `${data.class} nowrap`
        data.style['max-width'] = `${this.easyFormedHeaders[colName].width}px`
      }
      if(textAlign != 'center' && !columnInfo_.styleAttr && !columnInfo_.svgAttr) 
        data.style[`padding-${textAlign}`] = `${bodyProps_.textMargin}px`

      if(textAlign == 'left' && !this.easyFormedHeaders[colName].nowrap)
      data.style[`text-align`] = `left`
      
      if(this.easyFormedHeaders[colName].linebreak) {
        text = text.replace(/(?:\r\n|\r|\n)/g, '<br>')
        data.domProps = { innerHTML: text }
      }

      return [this.$createElement('div', data, [text])]
    },
    setLink(item, colName, text, tColor='') {
      let style = { textDecoration: 'underline', cursor: 'pointer'}

      let values_ = this.easyFormedHeaders[colName].link.values
      let valueObject_ = {}

      if (this.easyFormedHeaders[colName].link.action.target == 'url') {
        if(values_ && values_.length > 0) {
          values_.forEach(col_ => {
            let colParts__ = this.getColnamesForAction(col_)

            if(!colParts__.source) style = { textDecoration : 'none', cursor: 'default' }
            else if(!item[colParts__.source]) style = { textDecoration : 'none', cursor: 'default' }
            else valueObject_[colParts__.target] = item[colParts__.source]
          })
        }
      }

      let data = {
        on: { click: () => { 
          if(values_ && values_.length > 0) {
            values_.forEach(col_ => {
              let colParts__ = this.getColnamesForAction(col_)
              if(!colParts__.source) console.log(`[USER: undefined] Undefined column-name for the action-value.`)
              else if(!item[colParts__.source]) console.log(`[USER: not found '${colParts__.source}'] Not found matched value-column for the filter-value.`)
              else valueObject_[colParts__.target] = item[colParts__.source]
            })
          }

          if(this.easyFormedHeaders[colName].link.action.target == 'threedviewer') {
            this.easyFormedHeaders[colName].link.action.sender= 'DATA_LIST'
            this.easyFormedHeaders[colName].link.action.filters= valueObject_
            valueObject_ = {}
          }

          if(this.easyFormedHeaders[colName].link.action.target == 'url') {
            if (!valueObject_.LINK) return
            Object.values(valueObject_).forEach(v => {
              this.easyFormedHeaders[colName].link.action.path = v
            })
            valueObject_ = {}
          }

          this.$emit('request-action', {
            dataType: item.dataType,
            action: this.easyFormedHeaders[colName].link.action,
            filters: valueObject_,
            iFilters: { 
              filterString: this.easyFormedHeaders[colName].link.filterString || '',
              inputFilter: ''
            },
            data: this.easyFormedHeaders[colName].link.data || null
          })
          
        }},

        style: {
          'text-decoration': style.textDecoration,
          'cursor': style.cursor
        }
        
      }
      data.style.color = tColor || '#6c6b6c'

      return this.$createElement('a', data, [text])
    },
    setHeaderProperites(parent, parentPosition=null, cDepth=0) {
      let pChildren_ = parent.length

      // this.maxDepth: Max Depth, cDepth: Current Depth
      parent.forEach((h, i) => {
        // ### To pass current position information to the children ---
        let position_ = {
          depth: cDepth,
          siblings: pChildren_,
          siblingPosition: i + 1,
          parentPosition: parentPosition
        }
        // ------------------------------------------------------------

        h.depth = cDepth
        h.siblings = pChildren_
        h.siblingPosition = i + 1
        h.parentPosition = parentPosition
        
        if(this.freezeColumn && h.width && cDepth == 0) h['data-sticky-width'] = h['width']
        if(h.type == 'group') {
          // *** for the non shorter ****************************************
          // h.trindex = cDepth
          // h.colspan = this.getHeaderLength(h.children)
          // h.rowspan = 1

          // this.setHeaderProperites(h.children, position_, cDepth + 1)

          // *** for the group area shorter *********************************
          let childDepth = this.getHeaderDepth(h.children)

          let isShorter = this.maxDepth - cDepth - childDepth != 1
          let isLastShorter = childDepth == 1
          // last shorter group should have all the rest of shorter's rowspan
          let adjustedShorterRowspan = this.maxDepth - cDepth - childDepth
          let rowSpan = isShorter ? (isLastShorter ? adjustedShorterRowspan : 1) : 1

          h.trindex = cDepth
          h.colspan = this.getHeaderLength(h.children)
          h.rowspan = rowSpan

          this.setHeaderProperites(h.children, position_, cDepth + (isShorter && isLastShorter ? adjustedShorterRowspan - 1 : 0) + 1)
          // ****************************************************************
        } else {
          // *** for the non shorter ***
          h.trindex = cDepth
          // if the header type 'text | number', whatever colspan should be 1.
          h.colspan = 1
          // if the current depth is not 0, whatever current text
          // should be positioned to the end. it means that the header
          // type 'text | number' suppose to be the last element of the group.
          // but some case of sibling existing, this text has the rowspan
          // as deep as the siblings nested instead.
          let siblingExist = this.getHeaderDepth(parent) > 1
          h.rowspan = !siblingExist && cDepth ? 1 : this.maxDepth - cDepth

          // for the text area shorter **************************************
          // h.trindex = cDepth
          // // if the header type 'text', whatever colspan should be 1.
          // h.colspan = 1

          // // except, shorter
          // let childDepth = this.getHeaderDepth(parent)
          // let isShorter = (this.maxDepth - cDepth - childDepth) !== 0

          // // except, if the current depth is not 0, whatever current text
          // // should be positioned to the end. it means that the header
          // // type 'text | number' suppose to be the last element of the group.
          // // but some case of sibling existing, this text has the rowspan
          // // as deep as the siblings nested instead.
          // let siblingExist = childDepth > 1

          // h.rowspan = !(!siblingExist && cDepth) || isShorter ? (this.maxDepth - cDepth) : 1
          // ****************************************************************
        }
      })
      return parent
    },
    // -----------------------------------------------------------------

    codeAttrAvailable(styleType, codeType, code) { 
      if(styleType == 'style') return !!this.tableAttrs.style[codeType].find(attr => attr.code == code)
      else return !!this.tableAttrs.svg[codeType].attrs.find(attr => attr.code == code)
    },
    calHeaderDepth(h, dived = [], depth = 0) {
      h.forEach(h => {
        if (h.type == 'group') depth = this.calHeaderDepth(h.children, dived, ++depth).depth - 1
        dived.push(depth)
      })
      return { depth, dived }
    },
    flatten(parent, parentPosition, mDepth, cDepth = 0, flattened=[]) {
      let siblings = parent.length
      let siblingPosition = 1

      parent.forEach(h => {
        if ((['code']).includes(h.type) ) return

        // ### To pass current position information to the children ---
        let position_ = {
          depth           : cDepth,
          siblings        : siblings,
          siblingPosition : siblingPosition,
          parentPosition  : parentPosition,
          rowspan         : h.rowspan
        }
        // ------------------------------------------------------------
        let prop = {
          depth           : cDepth,
          siblings        : siblings,
          siblingPosition : siblingPosition,
          parentPosition  : parentPosition,
          rowspan         : h.rowspan,
          inputFilter     : h.inputFilter
        }

        this.generalPropNames.forEach(k => { prop[k] = h[k] })
        if(prop.sortable && !prop.sortOption) prop.sortOption = 'asc'

        siblingPosition++

        if (h.type == 'group') {
          flattened.push(prop)
          flattened = [...this.flatten(h.children, position_, mDepth, cDepth + 1, flattened)]
        } else flattened.push(prop)
      })

      return flattened
    },
    siftInvisible(h, sifted = []) {
      h.forEach(item => {
        if (item.type == 'group' && !this.siftable(item.children)) {
          let group_ = {}
          Object.keys(item).forEach(k => {
            if(k == 'children') group_['children'] = this.siftInvisible(item.children)
            else group_[k] = item[k]
          })
          sifted.push(group_)

        } else if (item.visible) sifted.push(item)
      })

      return sifted
    },
    siftable(h) {
      let siftable = true

      h.forEach(item => {
        if (!siftable) return

        if (item.type == 'group') siftable = this.siftable(item.children)
        else if (item.visible) siftable = false
      })

      return siftable
    },
    updatePresetItem(sourceItem, target) {
      let foundUpdated = false

      target.forEach(targetItem => {
        if (foundUpdated) return

        if (targetItem.type == 'group') foundUpdated = this.updatePresetItem(sourceItem, targetItem.children)
        else if (targetItem.value == sourceItem.value) {
          // remove non general props for the data secure && not merged unnecessary
          Object.keys(sourceItem).forEach(k => {
            if (!this.generalPropNames.includes(k)) delete sourceItem[k]
          })
          Object.assign(targetItem, sourceItem)
          foundUpdated = true
        }
      })

      return foundUpdated
    },
    updatePreset(flattened) {
      if (!flattened || flattened.length === 0) return []

      let sources = [...flattened.filter(flat => flat.type != 'group')]
      /* hard copy configuring values */
      // not used siftedHeaders, used prototype headers
      let configValues = JSON.parse(JSON.stringify(this.headers))

      sources.forEach(sourceItem => {
        this.updatePresetItem(sourceItem, configValues)
      })

      return configValues
    },

    onGanttComplete(v) {
      let el = document.querySelector('.gantt_timeline')
      el.style.width = `${v.w}px`
      el.style.minWidth = `${v.w}px`
      el.style.maxWidth = `${v.w}px`
      
      this.ganttInfo = v
    },
    onGanttXmlExported(v) {
      this.ganttExported = {
        ...this.ganttExported,
        ...v
      }

      this.$emit('chart-to-xml-string', this.ganttExported)
    },

    
    // p6 methods ### --------------------------------------------------
    genP6Viewer(tail=false) {
      if(!this.p6props || !this.p6props.levels) {
        console.error(`[USER: #DEBUG] The p6 property (level data) is not defined. Can't create the P6 Viewer.`)
        return null
      }

      let data = {
        class: {
          k_p6_viewer: true
        },
      }
      
      return this.$createElement('div', data, this.genP6Sector(0, tail))
    },
    genP6Sector(depth, tail, parent=null) {
      // if parent is not open, return empty elements
      if(parent && !this.isCollapsed(parent)) return []

      let sectorEls = []
      let levels = Object.keys(this.p6props.levels)
      let lvLength = levels.length

      // filter the level items by its parent level code //
      let datasource = this.items.filter(item => {
        // for the 0 level item
        if(!parent) return item[this.p6props.levelNoColName] == depth

        /**
         * make all the level codes into one line string concatenated.
         * higher level code doesn't have child level code (empty) in thier level column.
         * 
         * ex)
         * LEVEL 0: { LV1: 'P1', LV2: '', LV3: '', LV4: '', LV5: ''}
         * LEVEL 1: { LV1: 'P1', LV2: 'BTX', LV3: '', LV4: '', LV5: ''}
         * LEVEL 2: { LV1: 'P1', LV2: 'BTX', LV3: 'Completion', LV4: '', LV5: ''}
         * ...
         */

        // slice(0, depth) for the current depth
        return item[this.p6props.levelNoColName] == depth && levels.slice(0, depth).map(k => item[k]).join('') == parent
      })

      datasource.forEach(d => {
        let target = levels.map(k => d[k]).join('')
        // check to generate the activity items (end of level data) or level items
        // slice(0, depth+1) for the next depth (child level items)
        if(lvLength == depth+1) var childEls = this.isCollapsed(d) ? [this.$createElement(KimP6Activities, {
          ref: `${tail?'__tail':''}__${target}__`,
          props: {
            tail,
            parent: this,
            target: target,
            envs: this.p4act,
            p6props: this.p6props,
          },
          on: {
            'activity-retrieved': (v) => { this.$refs[`__tail__${target}__`].genActivities(v) },
            'tr-mouseover': (id) => { document.querySelectorAll(`#${id}`).forEach(el => { el.style.backgroundColor = '#eee' }) },
            'tr-mouseout': (id) => { document.querySelectorAll(`#${id}`).forEach(el => { el.style.backgroundColor = '#fff' }) },
          }
        })] : []
        else childEls = this.genP6Sector(depth+1, tail, levels.slice(0, depth+1).map(k => d[k]).join(''))

        let data = {
          class: {
            k_p6_sector: true,
            tail,
            opened: this.isCollapsed(d),
            closed: !this.isCollapsed(d),
          },
          style: { 
            ...this.p6props.levels[levels[depth]].style 
          }
        }
        if(tail) data.style.borderLeft = 'none'
        else data.style.borderRight = 'none'

        sectorEls.push(this.$createElement('div', data, [this.genP6GroupHeader(d, tail), ...childEls]))
      })

      return sectorEls
    },
    genP6GroupHeader(d, tail) {
      if(tail) {
        var icon, button

      } else {
        icon = this.$createElement(VIcon, [this.isCollapsed(d)?'mdi-minus':'mdi-plus'])
        button = this.$createElement('button', {
          class: {
            btn_collapse_open: true
          },
          on: { 
            click: () => { this.collapse(d) }
          }
        }, [icon])
      }
      
      return this.$createElement(
        'div', {
          class: {
            k_p6_sector__header: true
          },
        }, 
        [button, ...this.getP6GroupCells(d, tail)]
      )
    },
    getP6GroupCells(d, tail) {
      let lbLen_ = 0                                                // accumlated width for previous columns of groupLabelColName
      let levels = Object.keys(this.p6props.levels)
      let lvLen_ = levels.length
      let snames = Object.keys(this.stickyColumns)                  // sticky col names
      let hnames = this.getHeaderNames(this.siftedHeaders)          // all col names
      let tnames = hnames.filter(name => !snames.includes(name))    // tail col names
      let names_ = tail ? tnames : (snames.length>0 ? snames : hnames)

      names_ = names_.filter(n => !(['__STARTER__', '__SPACER__']).includes(n))

      if(names_.find(n => n == this.p6props.groupLabelColName)) {
        let idx = names_.findIndex(n => n == this.p6props.groupLabelColName)
        names_.slice(0, idx).forEach(n => { lbLen_ += Number(this.easyFormedHeaders[n].width) })
        names_ = names_.slice(idx, -1)
      }

      return names_.map((colName, i) => {
        let h_ = this.easyFormedHeaders[colName]

        if(colName == '__GANTT__') {
          let classWrapper = { group_gantt_wrapper: true }
          let isCollapsed = (
            d[this.p6props.levelNoColName] == 0 || 
            this.collapsed[levels.slice(0, d[this.p6props.levelNoColName]).map(k => d[k]).join('')]
          )
          if(!isCollapsed) return this.$createElement('div', { class: classWrapper })

          let ganttData = {
            props: {
              item      : d,
              size      : h_.size,
              keyName   : h_.keyColName,
              syncparent: this.ganttInfo
            },
          }
  
          ganttData.props.chart = { x: 1, y: 0 }
          ganttData.props.cutoffline = { y1: -2, y2: 3 }
          ganttData.props.monthline = { y1: -2, y2: 3, opacity: .15 }

          return this.$createElement('div', { class: classWrapper }, [this.$createElement(GanttSchedule, ganttData)])
        }

        let w_ = h_.width + (
          colName == this.p6props.groupLabelColName ? 
          lbLen_ + (lvLen_-1-d[this.p6props.levelNoColName]) + lvLen_*(5-Number(d[this.p6props.levelNoColName])) - lvLen_*5 + 7
          : (!i && tail ? 2 : 0)
        )
        let data = {
          class: {
            group_label: colName == this.p6props.groupLabelColName
          },
          style: {
            width: `${w_}px`,
            mixWidth: `${w_}px`,
            maxWidth: `${w_}px`,
          }
        }

        if(this.p6props.groupDisplayColNames.includes(colName)) {
          data.style.textAlign = h_.alignRowValue || 'center'
          data.style.borderLeft = i?this.p6props.levels[levels[d[this.p6props.levelNoColName]]].style.border:'none'
          data.style.borderRight = i==names_.length-1?this.p6props.levels[levels[d[this.p6props.levelNoColName]]].style.border:'none'
        }

        // addional info (ex. critical summary) ------------------------------
        if(this.p6props.addendums && this.p6props.addendums[colName]) {
          data.style.position = 'relative'
          // data.style.paddingLeft = '22px'

          let icon = this.$createElement(VIcon, { style: { fontSize: '14px' }}, ['mdi-dots-horizontal'])
          let summary = this.$createElement(GroupCriticalSummary, { 
            props: {
              item: d,
            },
            style: {
              pointerEvents: 'none'
            }
          })
          var addendum = this.$createElement('div', {
            class: {
              k_p6_group_column_addendum: true,
              right: true,
            },
            style: {
              height: '22px',
              width: '18px',
              backgroundColor: this.p6props.levels[levels[d[this.p6props.levelNoColName]]].style['background-color'],
              borderLeft: this.p6props.levels[levels[d[this.p6props.levelNoColName]]].style['border']
            },
            on: {
              mouseover: (e) => { e.target.style.width = `${h_.width}px` },
              mouseout: (e) => { e.target.style.width = '18px' }
            }
          }, [icon, summary])
        }
        // -------------------------------------------------------------------

        return this.$createElement('span', data, [d[colName], addendum])
      })
    },
    setCollapse() {
      let collapsed_ = {}
      let storaged = localStorage.getItem(`${this.collstorageName}_${this.idx}`)
      storaged = storaged ? JSON.parse(storaged) : {}

      this.items.forEach(d => {
        let k_ = Object.keys(this.p6props.levels).map(k => d[k]).join('')
        collapsed_[k_] = !!storaged[k_]
      })

      this.collapsed = collapsed_
    },
    setCollapseStorage() {
      localStorage.setItem(`${this.collstorageName}_${this.idx}`, JSON.stringify(this.collapsed))
    },
    isCollapsed(d) {
      if(typeof d == 'string') return this.collapsed[d]
      return this.collapsed[Object.keys(this.p6props.levels).map(k => d[k]||'').join('')]
    },
    collapse(d) {
      let k_ = Object.keys(this.p6props.levels).map(k => d[k]).join('')

      this.collapsed[k_] = !this.collapsed[k_]
      this.setCollapseStorage()
    },
    collapseAll(collapse) {
      if(collapse) this.$emit('loading', true)

      setTimeout(() => {
        let storaged = localStorage.getItem(`${this.collstorageName}_${this.idx}`)
        let keys = Object.keys(this.collapsed)
        let levels = Object.keys(this.p6props.levels)
        let lvLastItems = this.items.filter(item => item[this.p6props.levelNoColName] == levels.length-1)
        let collapsed_ = {}
  
        keys.forEach(k => { collapsed_[k] = collapse })
        lvLastItems.forEach(d => { 
          let k__ = levels.map(k => d[k]).join('')
          collapsed_[k__] = collapse ? !!storaged[k__] : false 
        })
  
        this.collapsed = collapsed_
        this.setCollapseStorage()
      })
    }
    // -----------------------------------------------------------------
  },
  render(h) {

    /* ### CAUSTION ############################################
     * Don't put any code in the render function, it causes the 
     * infinite loop occurs.
     * ######################################################### */

    return h('div', { class: 'j_datagrid' }, [this.genDatatable()] )
  }
}
